import { render, staticRenderFns } from "./RESENDORCHANGEPASSWORD.vue?vue&type=template&id=56584386&scoped=true"
import script from "./RESENDORCHANGEPASSWORD.vue?vue&type=script&lang=js"
export * from "./RESENDORCHANGEPASSWORD.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56584386",
  null
  
)

export default component.exports