<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<div class="font-semibold space-y-3">
								<p>Your username is the email address you used to sign up with.</p>
								<div class="w-8/12 space-y-1">
									<p>RESEND PASSWORD</p>
									<p class="font-normal">
										If you forgot your password or you just want to double check that you are using the correct password you can
										<span class="font-semibold">resend</span> your password to your account email address at the following link
									</p>
									<p class="font-normal">
										<a target="_blank" :href="`${$store.state.brandInfo.siteLink}/Portal/`">{{ $store.state.brandInfo.siteLink }}/Portal/</a>
									</p>
								</div>
							</div>
							<hr />
							<div class="font-semibold space-y-3">
								<p><span class="bg-yellow-300">YOU WILL BE TAKEN TO SCREEN BELOW</span></p>
								<p><span class="bg-yellow-300">SIMPLY CLICK FORGOT PASSWORD</span></p>
								<img class="pt-5" src="./../../../assets/images/mini-icon/account-area.png" alt="account-area" />
							</div>
							<div class="font-semibold space-y-3">
								<p><span class="bg-yellow-300">TYPE EMAIL ADDRESS IN THEN CLICK SUBMIT</span></p>
								<img class="pt-5" src="./../../../assets/images/mini-icon/forgot-password.png" alt="forgot-password" />
								<p class="text-xs">Password can take up to approximately 5 minutes to send to your email.</p>
							</div>
							<div class="font-semibold">
								<p class="underline text-lg">CHANGE PASSWORD</p>
								<p class="font-normal">
									Go to your myaccount
									<a :href="`${$store.state.brandInfo.siteLink}/Portal/`" class="hover:text-blue-600">{{ $store.state.brandInfo.siteLink }}/Portal/</a>
								</p>
								<p class="text-xs">Then click login</p>
								<img class="pt-5" src="./../../../assets/images/mini-icon/account-area.png" alt="myaccount-area" />
							</div>
							<div class="font-semibold">
								<p><span class="bg-yellow-300">Select Login and type your username and password then click SIGN-IN</span></p>
								<img class="pt-5" src="./../../../assets/images/mini-icon/sign-in.png" alt="sign-in-usernameOrPassword" />
							</div>
							<p class="font-semibold"><span class="bg-yellow-300">ONCE YOU HAVE SUCCESSFULLY LOGGED IN YOU WILL BE TAKEN TO SCREEN BELOW.</span></p>
							<div class="font-semibold space-y-2">
								<p><span class="bg-yellow-300">THEN SELECT CHANGE PASSWORD.</span></p>
								<img src="./../../../assets/images/mini-icon/profile.png" alt="subscription-expiry" />
							</div>
							<div class="font-semibold space-y-3">
								<p><span class="bg-yellow-300">YOU WILL BE TAKEN TO NEXT SCREEN AS SHOW BELOW</span></p>
								<p><span class="bg-yellow-300">TYPE NEW PASSWORD IN TWICE IN THE TOP AND BOTTOM ROWS THEN CLICK SUBMIT.</span></p>
								<p><span class="bg-yellow-300">ALL DONE!</span></p>
								<img src="./../../../assets/images/mini-icon/change-password.png" alt="subscription-expiry2" />
							</div>
							<div class="font-bold">
								<p><span class="bg-yellow-300">*Important: Do NOT use any special characters !@#$%^&*()./~`'-= etc</span></p>
								<p><span class="bg-yellow-300">ONLY use Letters (A-Z) and Numbers (0-9)</span></p>
							</div>
							<hr />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'RESENDORCHANGEPASSWORD',
	data() {
		return {
			title: this.$route.meta.title
		};
	}
};
</script>

<style scoped></style>
